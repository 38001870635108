<template>
  <div class="container" id="container" @click="delMenu">
    <!-- 顶部按钮 -->
    <div class="topbtn">
      <div class="box">
        <a href="javascript:;" @click="back"><img src="./img/icon-back.png" alt="返回" /></a>
        <div class="fr">
          <a href="javascript:;" @click="menu" ref="menu" id="menu"><img src="./img/icon-more.png" alt="菜单" /></a>
          <!-- 顶部目录 -->
          <div class="menu" v-show="showMenu">
            <div class="pointer"><span></span></div>
            <div class="menubox">
              <a @click="$router.push('/')"><img src="./img/icon-home.png" alt="首页" />首页</a>
              <a @click="userCenter"><img src="./img/icon-user.png" alt="个人中心" />我的订单</a>
              <a :href="'tel:' + tel" @click="menu"><img src="./img/icon-service.png" alt="联系客服" />联系客服</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 轮播 -->
    <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white" style="height: 5rem">
      <van-swipe-item v-for="(item, index) in slider" :key="index">
        <van-image fit="fill" :src="item.imgUrl" class="recr_swiper_item_img">
          <template v-slot:error>加载失败</template>
        </van-image>
      </van-swipe-item>
    </van-swipe>
    <!-- 固定的筛选条件 -->
    <div class="sort">
      <div class="hoteltype">
        <div class="box" :class="{'active':hoteltype == 0}" @click="changeHotelType(0)"><a href="javascript:;">酒店</a></div>
        <div class="box" :class="{'active':hoteltype == 1}" @click="changeHotelType(1)"><a href="javascript:;">民宿</a></div>
      </div>
      <div class="row location">
        <a href="javascript:;" @click="getlocation(1)"><img src="./img/icon-location.png" alt="当前位置" />当前位置</a>
        <input type="text" readonly placeholder="当前位置" v-model="areaName" @click="isAreaShow = true" />
      </div>
      <div class="row date" @click="showSelectDay = true">
        <div>
          <div class="dateto">入住 <span>{{$formatDate(new Date(dateArr[0]),"MM/dd")}}-{{$formatDate(new Date(dateArr[1]),"MM/dd")}}</span> 离店</div>
          <div class="day">共{{$getDaysBetween(dateArr[0],dateArr[1])}}晚</div>
        </div>
        <!--<div v-else>
          <div class="price">请选择入住时间</div>
        </div>-->
      </div>
      <div class="row searchkey d_f ali_c">
        <input type="text" v-model="searchkey" :placeholder="'位置/'+placeName+'名/关键词'" style="padding-right: 10px" />
        <van-icon class="fs_18" name="close" v-if="searchkey" @click="searchkey = ''" />
      </div>
      <div class="row price d_f ali_c" :class="(spText != '价格/星级' && spText) ? 'checked': ''">
        <span class="flex_1 text_over" @click="selectRating">{{spText}}</span>
        <van-icon class="fs_18" name="close" v-if="spText != '价格/星级'" @click="clearRating" color="#929191" />
      </div>
      <a href="javascript:;" class="btn" @click="search">搜索</a>
    </div>
    <div class="hotels">
      <!--  <div class="title"><span>推荐{{hoteltype==0?'酒店':'民宿'}}</span></div> -->
      <div class="title"><span>推荐{{hoteltype==0?'酒店':'民宿'}}</span><em @click="goMoreHotel(hoteltype)">更多>></em></div>
      <div class="list" v-if="hotels.length>0">
        <div class="hotel" v-for="(item, index) in hotels" :key="index">
          <div class="pic" :style='{ background:"url("+ item.cover +") no-repeat center center/cover"}' @click.stop="goInner(item,index)">
            <a href="javascript:;" :class="{'follow':item.collectId}" @click.stop="collect(item)">取消收藏</a>
          </div>
          <div class="info">
            <a href="javascript:;" @click="goInner(item,index)">
              <h1>{{item.hotelName}}</h1>
              <address><img src="./img/icon-address.png" alt="地址" />{{item.address}}</address>
              <div class="distance" v-if="item.distance">距您{{item.distance}}公里</div>
              <div class="price">￥{{item.minPirce ? item.minPirce/100 : '0'}}起/晚</div>
            </a>
          </div>
        </div>
      </div>
      <div v-else class="t_a no_list">
        <img src="./img/index_no_list.png" alt="">
        <div>暂无内容！</div>
      </div>
      <div class="loading" v-show="loading"><img src="./img/loading.png" alt="正在加载" />正在加载</div>
    </div>
    <!--日期弹窗-->
    <chooseDateRange v-show="showSelectDay" @closeCalendar="showSelectDay = false" @saveDate="saveDate"></chooseDateRange>
    <!--星级弹窗-->
    <starPrice v-if="showSelectRating" @close="showSelectRating = false" @save="saveSP" :isFull="false"></starPrice>

    <!--地区弹窗-->
    <chooseArea v-if="isAreaShow" @close="isAreaShow = false"></chooseArea>
    <div id="mapContainer" v-show="false"></div>
  </div>
</template>

<script>
import chooseDateRange from './component/chooseDateRange';
import chooseArea from './component/chooseArea';
import starPrice from './component/starPrice';
import apis from './utils/apis';
import { Icon, Swipe, SwipeItem, Image, Toast } from 'vant';
export default {
    name: 'hotelIndex',
    components: {
        chooseDateRange: chooseDateRange,
        chooseArea: chooseArea,
        starPrice: starPrice,
        'van-icon': Icon,
        'van-swipe': Swipe,
        'van-swipe-item': SwipeItem,
        'van-image': Image,
    },
    watch: {
        isAreaShow(n, o) {
            if (!n) {
                this.areaName = sessionStorage.getItem('area')
                    ? JSON.parse(sessionStorage.getItem('area')).name
                    : '峨眉山市';
                this.reloadList();
            }
        },
        hoteltype(n, o) {
            if (n == 0) {
                this.placeName = '酒店';
            } else {
                this.placeName = '民宿';
            }
        },
    },
    data() {
        return {
            tel: 4008196333, // 客服电话
            showMenu: false, // 顶部隐藏菜单显示/隐藏
            slider: [],
            hoteltype: 0, // 酒店/民宿
            nowlocation: '', // 当前位置
            showSelectDay: false, // 选择日期
            dateArr: sessionStorage.getItem('dateRage')
                ? JSON.parse(sessionStorage.getItem('dateRage'))
                : [
                      new Date().getTime(),
                      Number(new Date().getTime() + 24 * 3600 * 1000),
                  ], //选中的日期区间
            userSelectDays: null,
            totalDay: 0, // 入住天数
            searchkey: '', // 位置/酒店名/关键词
            showSelectRating: false, // 选择星级
            hasChecked: {}, // 已选择金额和星级
            infoSP: sessionStorage.getItem('starPrice')
                ? JSON.parse(sessionStorage.getItem('starPrice'))
                : {}, // 选择金额星级
            hotels: [],
            loading: false, // 列表加载中
            isAreaShow: false, // 地区弹窗
            areaName: sessionStorage.getItem('area')
                ? JSON.parse(sessionStorage.getItem('area')).name
                : '峨眉山市',
            pageIndex: 1,
            isLoadMore: true,
            spText: '价格/星级',
            isShowLoginPop: false,
            placeName: '酒店',
        };
    },
    created() {
        this.getShareInfo(
            this.$global.shareTitle,
            this.$global.visitUrl,
            this.$global.shareImage,
            this.$global.shareIntro
        );
        this.getBanner();
        this.reloadList();
        this.searchkey = sessionStorage.getItem('searchkey')
            ? sessionStorage.getItem('searchkey')
            : ''; // 搜索关键词
        if (sessionStorage.getItem('starPrice')) {
            this.delSPText();
        }
    },
    mounted() {
        //监听scroll事件
        window.addEventListener('scroll', this.handleScroll, true);
        //创建组件时，加载第1页数据
        this.getlocation();
    },
    destroyed() {
        //页面离开后销毁，防止切换路由后上一个页面监听scroll滚动事件会在新页面报错问题
        window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
        delMenu(e) {
            if (e.target.alt == '菜单') {
            } else {
                if (this.showMenu) {
                    this.showMenu = false;
                }
            }
        },
        changeHotelType(num) {
            this.hoteltype = num;
            this.reloadList();
        },
        clearRating() {
            this.spText = '价格/星级';
            sessionStorage.removeItem('starPrice');
        },
        delSPText() {
            let _t = this;
            //infoSP.price ? '￥'+infoSP.price[0]+'-'+infoSP.price[1]+'/'+infoSP.star.name : '价格/星级'
            let priceStr = _t.infoSP.price
                ? '￥' + _t.infoSP.price[0] + '-' + _t.infoSP.price[1]
                : '价格';
            let arr = [],
                starStr = '';
            _t.infoSP.star.map((item, index) => {
                if (item.acStar) {
                    arr.push(item.name);
                }
            });
            starStr = arr.length ? arr.join(',') : '星级';
            _t.spText = priceStr + ',' + starStr;
        },
        //跳转订单列表
        userCenter() {
            if (!this.$global.token) return (this.isShowLoginPop = true);
            sessionStorage.setItem('orderList', 1);
            this.$router.push({
                path: 'orderList',
                query: {
                    orderType: 1,
                },
            });
        },
        //跳转酒店内页done
        goMoreHotel(hoteltype) {
            this.$router.push({
                path: '/hotelMore',
                query: {
                    hoteltype:hoteltype,
                },
            });
        },
        //跳转酒店内页done
        goInner(row, index) {
            this.$router.push({
                path: '/hotelInner',
                query: {
                    sellerId: row.sellerId,
                },
            });
        },
        //返回首页done
        revert(num) {
            this.$router.go(-1);
        },
        //子组件保存星级价格done
        saveSP(info) {
            this.showSelectRating = false;
            this.infoSP = JSON.parse(JSON.stringify(info));
            this.delSPText();
        },
        // 获取banner done
        getBanner() {
            this.$get({
                url: apis.banner,
                params: {
                    position: 15, //固定值15，获取首页banner
                },
            }).then(rsp => {
                this.slider = rsp;
            });
        },
        // 顶部后退done
        back() {
            this.revert();
        },
        // 顶部隐藏菜单done
        menu() {
            this.showMenu = !this.showMenu;
        },
        //获取当前位置done
        getlocation(hasArea) {
            let _t = this;
            if (hasArea) {
                _t.areaName = '峨眉山市';
                localStorage.removeItem('map');
            }
            AMap.plugin('AMap.Geolocation', function () {
                var geolocation = new AMap.Geolocation({
                    // 是否使用高精度定位，默认：true
                    enableHighAccuracy: true,
                    // 设置定位超时时间，默认：无穷大
                    timeout: 10000,
                    // 定位按钮的停靠位置的偏移量，默认：Pixel(10, 20)
                    buttonOffset: new AMap.Pixel(10, 20),
                    //  定位成功后调整地图视野范围使定位位置及精度范围视野内可见，默认：false
                    zoomToAccuracy: true,
                    //  定位按钮的排放位置,  RB表示右下
                    buttonPosition: 'RB',
                });

                geolocation.getCurrentPosition();
                AMap.event.addListener(geolocation, 'complete', onComplete);
                AMap.event.addListener(geolocation, 'error', onError);

                function onComplete(data) {
                    // data是具体的定位信息
                    _t.areaName = data.addressComponent.district;
                    localStorage.setItem('map', JSON.stringify(data));
                    _t.reloadList();
                }
                function onError(data) {
                    _t.areaName = '峨眉山市';
                    _t.reloadList();
                    Toast('定位失败，为您跳转到峨眉山市');
                }
            });
        },
        reloadList() {
            this.isLoadMore = true;
            this.hotels = [];
            this.pageIndex = 1;
            this.searchHotelList();
        },
        //获取推荐列表
        searchHotelList() {
            if (!this.isLoadMore) return;
            this.$get({
                url: apis.searchHotelList,
                params: {
                    isRecommend: 1, //是否推荐
                    areaName: this.areaName,
                    sortName: 'sequence',
                    sortOrder: 'asc',
                    token: this.$global.token,
                    pageSize: 10,
                    pageIndex: this.pageIndex,
                    typeCode: this.hoteltype == 0 ? 'jd' : 'mins',
                },
            }).then(rsp => {
                if (rsp.data && rsp.data.length) {
                    this.hotels = this.hotels.concat(rsp.data);
                    this.loading = false;
                    if (rsp.data.length === 10) {
                        this.pageIndex = this.pageIndex + 1;
                    } else {
                        this.isLoadMore = false;
                    }
                } else {
                    this.isLoadMore = false;
                }
            });
        },

        //收藏事件响应事件
        collect(row) {
            if (!this.$global.token) return (this.isShowLoginPop = true);
            row.collectId ? this.cancelCollect(row) : this.addCollect(row);
        },
        addCollect(row) {
            //添加收藏
            const me = this;
            me.$post({
                url: apis.addCollect,
                params: {
                    token: me.$global.token,
                    bizType: 3,
                    bizId: row.sellerId,
                },
            }).then(rsp => {
                // Toast('操作成功');
                row.collectId = 1;
            });
        },
        cancelCollect(row) {
            //取消收藏
            const me = this;
            me.$post({
                url: apis.cancelCollect,
                params: {
                    token: me.$global.token,
                    bizType: 3,
                    bizId: row.sellerId,
                },
            }).then(rsp => {
                // Toast('操作成功');
                row.collectId = null;
            });
        },
        //日历保存
        saveDate(info) {
            this.dateArr = info;
            this.showSelectDay = false;
        },
        // 选择价格与星级done
        selectRating() {
            this.showSelectRating = true;
        },
        // 搜索酒店
        search() {
            if (this.searchkey) {
                sessionStorage.setItem('searchkey', this.searchkey);
            }
            sessionStorage.setItem(
                'hoteltype',
                this.hoteltype == 0 ? 'jd' : 'mins'
            );
            this.$router.push({
                path: '/hotelList',
            });
        },

        // 监听滚动条
        handleScroll() {
            let scrollTop = document.getElementById('app').scrollTop;
            let windowHeight = document.getElementById('app').clientHeight;
            let bodyHeight = document.getElementById('container')
                ? document.getElementById('container').clientHeight - 10
                : 0;
            if (scrollTop + windowHeight == bodyHeight && !this.loading) {
                this.searchHotelList();
            }
        },
    },
};
</script>

<style scoped src="./index.css">
</style>
